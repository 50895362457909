import React, { useEffect } from 'react';
import PropsType from 'prop-types';
import Image from 'next/image';

const ErrorImageTypes = {
    imageNotFound: '/images/image-not-found.svg',
    medicine: '/images/medicine-default.svg',
    medicine2x: '/images/medicine-default-2x.svg',
    nirogWaterMark: '/images/nirogstreet_watermark.svg',
    blog: '/images/icon_health_feed.svg',
    clinic: '/images/icon_clinics.svg',
    avatar: '/images/icon_avatar_default.svg',
    webinar: '/images/webinar-default.svg',
    clinicDefault: '/images/default-preview-image.svg',
}

const ImageNext = (props) => {
    const { className, src, alt, width, height, defaultImageFor, objectFit, onErrorScaleLevel,
        onErrorObjectFit, containerClass, priority = true, ...rest } = props;

    const [updatedImage, setUpdatedImage] = React.useState(src || ErrorImageTypes[defaultImageFor]);

    const onError = (e) => {
        console.log('Error res.hasHeader ', src)
        const defaultImagePath = ErrorImageTypes[defaultImageFor];
        setUpdatedImage(defaultImagePath);
        e.target.style.transform = `scale(${onErrorScaleLevel})`;
        e.target.style.objectFit = (onErrorObjectFit) ? 'cover' : 'contain';
    }

    useEffect(() => { setUpdatedImage(src || ErrorImageTypes[defaultImageFor]) }, [src])

    const ImageCom = <Image className={className} src={updatedImage} alt={alt} objectFit={objectFit}
        {...((rest?.layout && rest?.layout == 'fill') ? null : { width, height })} width={width} height={height}
        onError={onError} {...rest} priority={priority} />

    return containerClass
        ? <div className={containerClass}> {ImageCom}</div>
        : <>{ImageCom}</>
}

export default ImageNext;

ImageNext.defaultProps = {
    className: '',
    onErrorScaleLevel: 1,
    defaultImageFor: 'imageNotFound',
}

ImageNext.propTypes = {
    className: PropsType.string,
    src: PropsType.string,
    width: PropsType.number,
    height: PropsType.number,
    defaultImageFor: PropsType.oneOf(['medicine', 'nirogWaterMark', 'imageNotFound', 'medicine2x', 'blog', 'clinic']),
}
