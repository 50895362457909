import React from 'react';
import PropTypes from 'prop-types';


const classes = {
    regular: 'card--regular',
    regularMob: 'card--regularMob',
    regularTwo: 'card--regularTwo',
    square: 'card--square'
}

const Card = React.forwardRef(({ className, children, type, background, shadow, dataAutomation, showBackButton, backRoute = '/', ...rest }, ref) => {
    return (
        <div data-card={type} ref={ref} className={`${className} ${classes[type] ? classes[type] : ''} ${background ? `${background}` : ''}`} data-automation={dataAutomation} {...rest}>
            {children}
        </div>
    )
})

Card.defaultProps = {
    className: '',
    background: '',
    shadow: ''
}

Card.propTypes = {
    className: PropTypes.string,
    background: PropTypes.string,
    shadow: PropTypes.string,
    /**
     * Card content
     */
    // children: PropTypes.string,
    type: PropTypes.oneOf(['square', 'regularTwo', 'regular', 'regularMob'])
}

export default Card;