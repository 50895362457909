import React from 'react'
import Text from './Text'

const DiscItem = ({ className, textType, fontWeight, children }) => {
    return (
        <Text type={textType} fontWeight={fontWeight} className={`${className} inline-flex justify-end items-center`}>
            <span className='w-1.5 h-1.5 rounded-full bg-gray-500 inline-block mr-1'></span>
            <span className='align-middle inline-block'>{children}</span>
        </Text>
    )
}

export default DiscItem