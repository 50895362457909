import React from 'react';

import PropTypes from "prop-types";
import SvgIcon from './SvgIcon';
import ImageNext from './ImageNext';


const TextClasses = {
    bodySmall: 'bodySmallText',
    bodyLarge: 'text--bodyLarge',
    body: 'bodyText',
    label: 'labelText',
    subtitle: 'subtitleText',
    caption: 'captionText',
    captionSmall: 'captionSmallText',
    paragraph: 'paragraphText',
    bold_v2: 'bold_v2',
    overline: 'text--overline'
}

/**
 * ## Font-Size Chart
 * 
 * | size             | font-size | line-height |
 * | ---------------- | --------- | ----------- |
 * | **bodySmall**    | 14        | 20          |
 * | **body**         | 16        | 24          |
 * | **bodyLarge**    | 18        | 20          |
 * | **label**        | 10        | 12          |
 * | **subtitle**     | 20        | 24          |
 * | **caption**      | 12        | 20          |
 * | **captionSmall** | 12        | 12          |
 * | **paragraph**    | 16        | 24          |
 * | **bold_v2**      | 16        | 24          |
 * | **overline**     | 16        | 20          |
 */

const FontWeight = {
    400: 'font-normal',
    500: 'font-medium',
    600: 'font-semibold',
    700: 'font-bold',
}

const TAG = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    span: 'span',
    p: 'p',
    figcaption: 'figcaption',
}
const ICON_DIRECTION = {
    LEFT: '',
    RIGHT: 'flex-row-reverse',
    TOP: 'flex-col',
    BOTTOM: 'flex-col flex-col-reverse',
}
const Text = ({ className, type, children, minLength, color, fontWeight, iconType, iconClass, dataAutomation, iconSize, iconDirection, iconImageWidth, iconImageHeight, iconImageSrc, tag, allowSelection, fontSize, ...rest }) => {
    const Type = TAG[tag] ? TAG[tag] : 'div';
    return <Type className={`${className} ${fontSize || ''} ${(iconType || iconImageSrc) ? 'inline-flex items-center' : ''} ${ICON_DIRECTION[iconDirection] || ''} textDefault ${TextClasses[type] || TextClasses.body} ${rest.onClick ? 'font-bod cursor-pointer ' : ''} ${allowSelection==true ? '' : 'select-none '} ${color || ''} ${FontWeight[fontWeight] || ''}`} data-automation={dataAutomation} {...rest}>
        {iconType && <SvgIcon className={`${iconClass} shrink-0`} type={iconType} size={iconSize} />}
        {iconImageSrc && <ImageNext className={`${iconClass} shrink-0`} src={iconImageSrc} alt='icon' width={iconImageWidth} height={iconImageHeight} />}
        {children}
    </Type>
}

Text.propTypes = {
    className: '',
    color: '',
    fontWeight: '',
    iconClass: '',
}


Text.propTypes = {
    className: PropTypes.string,
    /**
     * Which type should the button be?
     */
    type: PropTypes.oneOf(['bodySmall', 'body', 'bodyLarge', 'label', 'subtitle', 'caption', 'captionSmall', 'paragraph', 'bold_v2', 'overline']),
    color: PropTypes.string,
    fontWeight: PropTypes.number,
    iconClass: PropTypes.string,
}

export default Text;